/* eslint-disable @typescript-eslint/camelcase */
import * as React from 'react';
import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import { Filmography } from './filmo';
import { BackButton } from '../../components/back-button';
import { Icon } from '../../components/icons';
import SEO from '../../components/seo';
import { Sizer } from '../../components/image-sizer';
import { ImageSlider } from '../../components/image-slider';
import { ArtistName } from './artist-name';
import { mqs } from '../../components/style-utils';
import { ZONE_IMG_SIZE } from '../../config';
const Layout = styled.div `
  position: relative;
  top: 8rem;
`;
const StyledHeader = styled.div `
  position: relative;
  color: ${({ theme }) => theme.fg};

  ${mqs.map((mq, i) => `
    ${mq} {
      & {
        width: ${ZONE_IMG_SIZE[i]}%;
      }
    }
  `)}
`;
const ArtistPage = ({ data, pageContext }) => {
    const { artist, translate } = data;
    const { t_back_button } = translate.translation;
    const { gallery, nameKo, nameEn, nameJa, nameZh, socialMedia, filmography, profilePhoto, } = artist;
    const { lang } = pageContext;
    const name = {
        ko: nameKo,
        en: nameEn,
        ja: nameJa,
        zh: nameZh,
    };
    const mainName = lang === 'en' || !name[lang] ? nameKo : name[lang];
    const images = gallery
        ? gallery.map(image => ({
            fluid: image.localFile.childImageSharp.fluid,
            alt: mainName,
        }))
        : [];
    const cover = {
        fluid: profilePhoto.landscapeFile.childImageSharp.fluid,
        alt: mainName,
    };
    return (<React.Fragment>
      <BackButton>
        {t_back_button[lang]} <Icon size={1.75} iconName="close"/>
      </BackButton>
      <Layout>
        <SEO title={`${mainName} ${nameEn}`} lang={lang}>
          <script type="application/ld+json">
            {`
            {
              "@context": "http://schema.org",
              "@type": "Person",
              "name": "${mainName}",
              "url": "https://bhent.co.kr${artist.fields.slug}",
              "image": "https://bhent.co.kr${cover.fluid.src}",
              ${Array.isArray(socialMedia) ? `"sameAs": ${JSON.stringify(socialMedia.map(sm => sm.url))}` : ''}
            }
            `}
          </script>
        </SEO>
        <StyledHeader>
          <Sizer ratio={2}>
            <ImageSlider cover={cover} images={images}/>
          </Sizer>
          <ArtistName {...{
        mainName,
        nameEn,
        socialMedia,
    }}/>
        </StyledHeader>
        <Filmography lang={lang} filmography={filmography}/>
      </Layout>
    </React.Fragment>);
};
export default ArtistPage;
export const query = graphql `
  query Artist($slug: String!) {
    translate: contentJson(type: { eq: "translation" }) {
      translation {
        t_back_button {
          en
          ko
          ja
          zh
        }
      }
    }
    artist(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      nameKo
      nameEn
      nameJa
      nameZh
      socialMedia {
        type
        url
      }
      gallery {
        image
        localFile {
          childImageSharp {
            fluid(maxWidth: 1600, sizes: "100vw", quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      profilePhoto {
        landscapeFile {
          childImageSharp {
            fluid(
              maxWidth: 1024
              sizes: "(max-width: 900px) 100vw, (max-width: 1280px) 1024px, 1024px"
              quality: 70
            ) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
      filmography {
        type
        typeEn
        entries {
          title
          titleEn
          year
          network
        }
      }
    }
  }
`;
