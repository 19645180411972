import * as React from 'react';
import styled from '@emotion/styled';
import { useStaticQuery, graphql } from 'gatsby';
import startCase from 'lodash/startCase';
import { mqs } from '../../components/style-utils';
import { ZONE_LEFT_MARGIN } from '../../config';
import { PoseFilmo } from './poses';
export const FilmoEmpty = () => <h1>No filmography yet</h1>;
const StyledFilmoEntry = styled.section `
  position: relative;
  padding-bottom: 4rem;
  color: ${({ theme }) => theme.fg};
  max-width: 50%;

  header h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .filmo-entry {
    position: relative;
    font-size: 1.1rem;

    & > p {
      font-weight: 300;
      font-weight: lighter;
    }
  }

  .filmo-entry-network {
    letter-spacing: 0.15px;
    opacity: 0.6;
  }

  .filmo-entry-year {
    position: absolute;
    text-align: right;
    top: 0;
    left: -1rem;
    transform: translateX(-100%);
    opacity: 0.4;
    font-family: 'helv', Helvetica;
  }

  .filmo-entry,
  .filmo-entry-network {
    line-height: 1.5rem;
    margin-bottom: 1rem;
    vertical-align: middle;
  }

  ${mqs[0]} {
    header h1 {
      font-size: 1.5rem;
      margin-bottom: 1.25rem;
    }

    .filmo-entry {
      font-size: 0.9rem;
      line-height: 1.4rem;
    }

    .filmo-entry-year,
    .filmo-entry-network {
      font-size: 0.8rem;
    }

    .filmo-entry-year {
      left: -0.75rem;
    }
  }

  ${mqs[1]} {
    header h1 {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .filmo-entry {
      font-size: 1.125rem;
    }

    .filmo-entry-year,
    .filmo-entry-network {
      font-size: 1rem;
    }

    .filmo-entry-year {
      left: -0.75rem;
    }
  }

  ${mqs.map((mq, i) => `${mq} {
    margin-left: ${ZONE_LEFT_MARGIN[i]}%;
  }`)}
`;
const getDisplayLang = (lang, koreanText, englishText) => lang === 'ko' || !englishText ? koreanText : englishText;
const FilmEntryHeader = ({ type, lang }) => {
    const data = useStaticQuery(graphql `
    {
      contentJson(type: {
        eq: "setting/filmo-cats"
      }) {
        cats {
          nameKo
          nameEn
          nameJa
          nameZh
        }
      }
    }
  `);
    const { cats } = data.contentJson;
    const cat = cats.find(({ nameKo }) => nameKo === type);
    const displayCat = cat[`name${startCase(lang)}`] || cat.nameEn;
    return <h1>{displayCat}</h1>;
};
export const FilmoEntry = ({ lang, filmoEntry }) => {
    const { type, entries } = filmoEntry;
    return (<StyledFilmoEntry>
      <header>
        <FilmEntryHeader type={type} lang={lang}/>
      </header>
      <ol>
        {entries && entries.length > 0 ? entries.map((entry, i) => {
        const displayTitle = getDisplayLang(lang, entry.title, entry.titleEn);
        return (<li className="filmo-entry" key={i}>
              <p>
                {entry.network && (<span className="filmo-entry-network">{entry.network}</span>)}{' '}
                {displayTitle}
              </p>
              {entry.year && <p className="filmo-entry-year">{entry.year}</p>}
            </li>);
    }) : null}
      </ol>
    </StyledFilmoEntry>);
};
export const Filmography = ({ filmography, lang }) => {
    if (!filmography || filmography.length === 0)
        return <FilmoEmpty />;
    return (<PoseFilmo>
      <ul>
        {filmography.map((filmoEntry, i) => (<li key={i}>
            <FilmoEntry lang={lang} filmoEntry={filmoEntry}/>
          </li>))}
      </ul>
    </PoseFilmo>);
};
