import * as React from 'react';
import styled from '@emotion/styled';
import pose from 'react-pose';
const TRANSITION = {
    duration: 150,
};
const Transition = pose.a({
    enter: { opacity: 1, transition: TRANSITION },
    exit: { opacity: 0, transition: TRANSITION },
});
const StyledBackButton = styled(Transition) `
  position: absolute;
  top: 0;
  right: 0;
  padding: 1.5rem;
  text-decoration: none;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.fg};
  opacity: 0.6;
  z-index: 300;

  & > svg {
    opacity: 0.8;
  }

  & > * {
    vertical-align: middle;
  }
`;
const BackButton = React.forwardRef(({ children, ...props }, ref) => {
    const onClick = (e) => {
        e.preventDefault();
        history.back();
    };
    return (<StyledBackButton {...props} ref={ref} href="#" onClick={onClick}>
        {children}
      </StyledBackButton>);
});
BackButton.displayName = 'BackButton';
export { BackButton };
