import * as React from 'react';
import styled from '@emotion/styled';
import { mqs } from '../../components/style-utils';
import { PoseArtistName } from './poses';
import { Icon } from '../../components/icons';
export const Name = styled(PoseArtistName) `
  position: relative;
  text-align: right;
  font-size: 2.25rem;
  font-weight: 200;
  margin: 1.5rem 0 4rem;

  & > span {
    display: inline-block;
    padding-left: 1rem;
  }

  ${mqs[0]} {
    text-align: left;
    font-size: 1.75rem;
    margin-left: 33.33%;

    & > span {
      display: block;
      padding-left: 0;
      margin-bottom: 0.5rem;
    }
  }

  ${mqs[1]} {
    text-align: left;
    font-size: 2rem;
    margin-left: 33.33%;

    & > span {
      display: block;
      padding-left: 0;
      margin-bottom: 0.5rem;
    }
  }
`;
const Social = styled.ul `
  position: absolute;
  right: 0;
  top: 0;
  box-sizing: border-box;
  padding: 0 1rem;
  transform: translateX(100%);
  max-width: 33.33%;

  li {
    display: inline-block;
    margin-right: 1rem;
  }

  ${mqs[0]} {
    position: relative;
    display: block;
    transform: none;
    right: 0;
    max-width: 100%;
    padding: 0;
    margin-top: 0.75rem;
  }

  ${mqs[1]} {
    position: relative;
    display: block;
    transform: none;
    right: 0;
    max-width: 100%;
    padding: 0;
    margin-top: 0.5rem;
  }

  a {
    opacity: 0.4;
    transition: opacity 0.3s ease;
  }

  a:hover {
    opacity: 1;
  }
`;
export const ArtistName = ({ mainName, nameEn, socialMedia }) => {
    return (<Name>
      <span>{mainName}</span>
      <span>{nameEn}</span>
      {socialMedia && (<Social>
          {socialMedia.map(item => (<li key={item.type}>
              <a href={item.url} target="blank" title={`${mainName} ${item.type}`}>
                <Icon size={1.5} iconName={item.type}/>
              </a>
            </li>))}
        </Social>)}
    </Name>);
};
