import pose from 'react-pose';
const TRANSITION = {
    ease: 'easeOut',
    duration: 300,
};
export const PoseFilmo = pose.article({
    enter: {
        opacity: 1,
        y: 0,
        transition: TRANSITION,
        delay: 300,
    },
    exit: {
        opacity: 0,
        y: -15,
        transition: TRANSITION,
    },
});
export const PoseArtistName = pose.h1({
    enter: {
        opacity: 1,
        y: 0,
        transition: TRANSITION,
        delay: 200,
    },
    exit: {
        opacity: 0,
        y: -15,
        transition: TRANSITION,
    },
});
