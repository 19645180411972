import * as React from 'react';
import styled from '@emotion/styled';
import { IconButton } from '../icon-button';
const StyledMenu = styled.div `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.5rem;
  z-index: 300;
  font-size: 1.25rem;
  font-weight: bolder;
  color: #fff;
  box-sizing: border-box;
  text-align: right;

  & span {
    display: inline-block;
    vertical-align: middle;
    padding-right: 0.75rem;
    text-shadow: 0 2px 8px rgba(0, 0, 0, 0.24);
  }

  /* &.is-enlarged {
    position: relative;
    height: 3.5rem;
  } */

  .slider-controller,
  .slider-enlarge {
    height: 3rem;
    display: inline-block;
    position: relative;
  }

  &.is-enlarged .slider-controller {
    position: absolute;
    width: 40%;
    min-width: 16rem;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    transform: translateX(-50%);
  }

  &.is-enlarged .slider-enlarge {
    position: absolute;
    right: 1rem;
  }
`;
export const Menu = ({ currentId, amount, toggleEnlarge, title, next, isEnlarged, }) => {
    const handleToggleEnlarge = () => toggleEnlarge();
    const handleNext = () => next(1);
    const handlePrev = () => next(-1);
    return (<StyledMenu className={`${isEnlarged ? 'is-enlarged' : ''}`}>
      <div className="slider-controller">
        {isEnlarged && (<IconButton color="#fff" iconName="previous" onClick={handlePrev}/>)}
        <span>{title}</span>
        <span>
          {currentId} / {amount}
        </span>
        {isEnlarged && (<IconButton color="#fff" iconName="next" onClick={handleNext}/>)}
      </div>
      <div className="slider-enlarge">
        <IconButton color="#fff" iconName="enlarge" onClick={handleToggleEnlarge}/>
      </div>
    </StyledMenu>);
};
