import * as React from 'react';
import { useState } from 'react';
import { PoseGroup } from 'react-pose';
import GatsbyImage from 'gatsby-image/withIEPolyfill';
import { PoseSlider, 
// StyledImage,
PoseImageContainer, PoseOverlay, ImageCover, } from './index.pose';
import { Menu } from './menu';
export const ImageSlider = ({ images, cover }) => {
    const amount = images.length;
    const [isEnlarged, setEnlarge] = useState(false);
    const [currentId, setCurrentId] = useState(1);
    const getImg = (id) => images[id - 1];
    const currentImg = getImg(currentId);
    const next = (step = 1) => {
        let nextId = currentId + step;
        if (nextId > amount)
            nextId = 1;
        if (nextId < 1)
            nextId = amount;
        setCurrentId(nextId);
    };
    const toggleEnlarge = (nextState) => {
        let shouldEnlarge = typeof nextState === 'undefined' ? !isEnlarged : nextState;
        setEnlarge(shouldEnlarge);
    };
    const pose = isEnlarged ? 'enlarged' : 'init';
    return (<div style={{
        position: 'relative',
        zIndex: isEnlarged ? 1100 : 800,
        width: '100%',
        height: '100%',
    }}>
      <PoseSlider pose={pose}>
        {amount > 0 && <Menu title={currentImg.alt} currentId={currentId} amount={amount} isEnlarged={isEnlarged} toggleEnlarge={toggleEnlarge} next={next}/>}
        <ImageCover isEnlarged={isEnlarged}>
          <GatsbyImage style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
    }} critical fadeIn={true} objectFit="cover" fluid={cover.fluid}/>
        </ImageCover>
        <PoseGroup>
          {amount > 0 && <PoseImageContainer isEnlarged={isEnlarged} key={`${currentImg.alt}-${currentId}`}>
            <GatsbyImage style={isEnlarged
        ? {
            height: '100%',
            width: '100%',
        }
        : {}} fadeIn={true} objectFit="contain" fluid={currentImg.fluid}/>
          </PoseImageContainer>}
        </PoseGroup>
      </PoseSlider>
      <PoseOverlay pose={pose}/>
    </div>);
};
