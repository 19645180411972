import pose from 'react-pose';
import styled from '@emotion/styled';
const EASE = [0.08, 0.69, 0.2, 0.99];
const transition = {
    duration: 500,
    ease: EASE,
};
const Transition = pose.div({
    enter: {
        opacity: 1,
        transition,
    },
    exit: {
        opacity: 0,
        transition,
    },
});
export const PoseImageContainer = styled(Transition) `
  position: relative;
  width: 100%;
  top: ${({ isEnlarged }) => (isEnlarged ? '3.5rem' : 0)};
  height: ${({ isEnlarged }) => (isEnlarged ? 'calc(100% - 3.5rem)' : '100%')};
  transform-origin: center;
`;
const Slider = pose.figure({
    init: {
        position: 'relative',
        width: '100%',
        height: '100%',
        flip: true,
        transition,
    },
    enlarged: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        flip: true,
        transition,
    },
    enter: {
        delay: 500,
        opacity: 1,
        transition: {
            duration: 0,
        },
    },
    exit: {
        opacity: 0,
    },
});
export const PoseSlider = styled(Slider) `
  position: relative;
  width: 100%;
  height: 50vh;
  overflow: hidden;
  font-size: 0;
  z-index: 900;
  /* background: #010d1e; */
`;
const Overlay = pose.div({
    enlarged: {
        opacity: 1,
    },
    init: {
        opacity: 0,
    },
});
export const PoseOverlay = styled(Overlay) `
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #010d1e;
`;
export const StyledImage = styled.img `
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: ${({ isEnlarged }) => (isEnlarged ? 'contain' : 'cover')};
`;
export const ImageCover = styled.div `
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
  display: ${({ isEnlarged }) => (isEnlarged ? 'none' : 'block')};
`;
